.contact__container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
}

.contact__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}

.contact__card-icon {
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title, .contact__card-data {
    font-size: var(--small-font-size);
}

.contact__card-title {
    font-weight: var(--font-medium);
}

.contact__card-data {
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact__button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.contact__button:hover .contact__button-icon {
    transform: translate(0.25rem);
}

.contact__form {
    width: 360px;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area {
    height: 11rem;
}

.contact__form-area textarea {
    resize: none;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
    .contact__container {
        column-gap: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .contact__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .contact__info {
        justify-content: center;
    }

    .contact__form {
        margin: 0 auto;
    }

}

@media screen and (max-width: 576px) {
    .contact__info {
        grid-template-columns: 1fr;
    }

    .contact__form {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
}

.email-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

.email-modal__content {
    background: var(--container-color);
    padding: 3rem 4rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    animation: slideUp 0.3s ease-in-out;
    max-width: 500px;
    width: 90%;
    margin: 1rem;
}

.email-modal__spinner {
    width: 60px;
    height: 60px;
    border: 4px solid var(--title-color);
    border-radius: 50%;
    border-top-color: transparent;
    margin: 0 auto 1.5rem;
    animation: spin 1s linear infinite;
}

.email-modal__success,
.email-modal__error {
    display: block;
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.email-modal__message {
    color: var(--title-color);
    font-size: var(--h3-font-size);
    line-height: 1.6;
    margin-bottom: 1rem;
}

.email-modal__link {
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: var(--font-medium);
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.email-modal__link:hover {
    background-color: var(--title-color-dark);
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 576px) {
    .email-modal__content {
        padding: 2rem;
        width: 95%;
    }

    .email-modal__message {
        font-size: var(--normal-font-size);
    }
}

.email-modal__linkedin-link {
    color: var(--title-color);
    font-weight: var(--font-medium);
    text-decoration: underline;
    transition: color 0.3s;
}

.email-modal__linkedin-link:hover {
    color: var(--title-color-dark);
}

.email-modal__button {
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
    font-weight: var(--font-medium);
    transition: all 0.3s ease;
    margin-top: 1.5rem;
    border: none;
    cursor: pointer;
}

.email-modal__button:hover {
    background-color: var(--title-color-dark);
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}